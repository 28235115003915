// Define all your action constant for all application in this file
export const ActionConsts = {
  Auth: {
    ON_LOADING_START: "ON_LOADING_START",
    ON_LOADING_FINISHED: "ON_LOADING_FINISHED",
    UPDATE_ALERT_MESSAGE: "UPDATE_ALERT_MESSAGE",
    UPDATE_USER_TOKEN: "UPDATE_USER_TOKEN",
    USER_INFORMATION: "USER_INFORMATION",
    ORG_INFORMATION: "ORG_INFORMATION",
    UPDATE_MULTIUSER_DETAIL: "UPDATE_MULTIUSER_DETAIL",
    SCREEN_LOADING: "SCREEN_LOADING",
    SET_NOTIFICATION_DETAIL: "SET_NOTIFICATION_DETAIL"
  },
  Header: {
    ON_SEARCH_FIELD: "ON_SEARCH_FIELD",
  },
  CompanyDirectory: {
    ON_SET_FIELD_VALUE: "ON_SET_FIELD_VALUE",
    ON_SET_USER_LIST: "ON_SET_USER_LIST",
    ON_RESET_FIELDS: "ON_RESET_FIELDS",
  },
  File: {
    FILE_DOWNLOAD: "FILE_DOWNLOAD"
  }
};
