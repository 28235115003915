export const ApiRoutes = {
  login: "login",
  home: "home",
  refreshToken: "tokens/exchange",
  logout: "users/logout",
  password: "password",
  passwordEmailHash: "validate",
  setPassword: "set-password",
  changePassword: "change",
  users: "users",
  roles: "master-data/roles",
  meetings: "meetings",
  groupMeetings: "group-meetings",
  update: "update",
  meetingList: "list",
  jobTitle: "job-title",
  department: "department",
  questionnaire: "questionnaire",
  answers: "answers",
  questionTypeList: "master-data/response-types",
  cancel: "cancel",
  meetingAgenda: "meeting-agenda",
  meetingFollowUp: "meeting-followup",
  notes: "notes",
  timeline: "timeline",
  settings: "settings",
  goals: "goals",
  archived: "archived",
  order: "order",
  fileBaseUrl: process.env.REACT_APP_API_BASE_URL,
  file: "files",
  notifications: "notifications",
  upcoming: "upcoming",
  manager: "manager",
  changeRequest: "change-request",
  dashboard: "dashboard",
  onTimeMeeting: "on-time-meetings",
  organization: "organization",
  kpi: "kpi",
  forgot: "forgot",
  emailVerify: "login/email-verify",
  companyVerify: "signup/company-verify",
  futureKpi: "future/add/remove",
  futureAddKpi: "kpi/add/future-kpi",
  versionHistory: "version-history",
  timeLine: "timeline",
  todos: "todos",
  feedback: "feedback",
};
