import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Routes } from './constant/WebRoutes';
import JwtService from './utils/JwtService';
import TokenServices from 'src/utils/TokenServices';

const PublicRoute = prop => {
  const tokenExpired = JwtService.isTokenExpired();
  const tokenValue = JwtService.getTokenValues();

  const redirectPage = decodeTokenValues => {
    switch (decodeTokenValues.roleId) {
      case 1:
        return <Redirect to={Routes.padashboard} />;
      case 2:
        if(decodeTokenValues.screen === "client_admin") {
          return <Redirect to={Routes.casetting.concat('/goals')} />;
        }
        else { 
          return <Redirect to={Routes.dashboard} />;
        }
      case 3:
      case 4:
        return <Redirect to={Routes.dashboard} />;
      default:
        TokenServices.removeLocalStorage();
        return <Redirect to={Routes.login} />;
    }
  };

  return prop.forceClose || tokenExpired ? (
    prop.hasOwnProperty('redirect') && prop.redirect ? (
      <Redirect to={prop.redirect} />
    ) : (
      <Route path={prop.path} exact={prop.exact} component={prop.component} />
    )
  ) : (
    redirectPage(tokenValue)
  );
};
export default PublicRoute;
