// #region Global Imports
import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
// #endregion Global Imports

// #region Local Imports
import rootReducer from "src/reducers";
// #endregion Local Imports

const loggerMiddleware = createLogger();
function noop() {}
export default function configureStore(preloadedState = {}) {
  let middlewares = [thunkMiddleware, loggerMiddleware];

  if (process.env.REACT_APP_STAGE === "dev") {
    middlewares = [thunkMiddleware, loggerMiddleware];
  } else {
    middlewares = [thunkMiddleware];
    console.warn = noop;
    console.error = noop;
  }
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}
