import jwt_decode from "jwt-decode";
import TokenServices from "./TokenServices";

class JwtService {
    getTokenValues() {
        const token = TokenServices.getLocalAccessToken();
        const decoded = token ? jwt_decode(token) : null;
        return decoded;
    }
    isTokenExpired() {
        const toknDecode = this.getTokenValues();
        if (!toknDecode || (toknDecode && toknDecode.exp * 1000 < Date.now())) {
            return true;
        }
        return false;
    }
}

export default new JwtService();