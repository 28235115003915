import React, { useState } from 'react';
import { Layout, Spin } from 'antd';
import CHeader from 'src/components/Header';
import CFooter from 'src/components/Footer';
import CSidebar from 'src/components/Sidebar';
// import FeedbackButton from 'src/components/FeedbackButton';
import JwtService from 'src/utils/JwtService';
import { useSelector } from 'react-redux';
const { Header, Footer, Sider, Content } = Layout;

const HeaderLayout = props => {
  const decodeTokenValues = JwtService.getTokenValues();
  const loading = useSelector(state => state.auth.screenLoading);

  let [sidebarHighlight] = useState('');
  let [bodyHighlight] = useState('');

  switch (decodeTokenValues.roleId) {
    case 1:
      sidebarHighlight = 'admin-sidebar';
      bodyHighlight = 'admin-body';
      break;
    case 2:
      if (decodeTokenValues.screen === "client_admin") {
        sidebarHighlight = 'companyadmin-sidebar';
      }
      else {
        sidebarHighlight = 'manager-sidebar';
      }
      break;
    case 3:
    case 4:
      sidebarHighlight = 'manager-sidebar';
      break;
    default:
      sidebarHighlight = '';
      break;
  }

  return (
    <>
      {/*  <Header /> */}
      <Spin tip='Loading...' spinning={loading ? true : false} size='large'>
        <Layout>
          <Sider className={`sidebar ${sidebarHighlight}`}>
            <CSidebar />
          </Sider>
          <Layout>
            <Header className={`header ${bodyHighlight}`}>
              <CHeader />
            </Header>
            <Content className={`maincontent ${bodyHighlight}`}>
              {props.children}
              {/* <FeedbackButton /> */}
            </Content>
            <Footer className={`${bodyHighlight}`}>
              <CFooter />
            </Footer>
          </Layout>
        </Layout>
      </Spin>
    </>
  );
};

export default HeaderLayout;
