import React from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { Routes } from "src/constant/WebRoutes";
const Footer = ({ version }) => {
  const curretYear = () => moment().format("YYYY");
  const history = useHistory();
  const versionRedirect = () => {
    history.push(Routes.versionHistoryDetailsFromLogin);
  };

  return (
    <div className="d-flex-j">
      <div>All rights reserved ConnectUs {curretYear()}</div>
      <div
        style={{
          paddingLeft: "0.5rem",
          textDecoration: "underline solid #5BCAE4",
          cursor: "pointer",
        }}
        onClick={() => versionRedirect()}
      >
        {`${version?.data?.versionId ? `v${version?.data?.versionId}` : ""}`}
      </div>
    </div>
  );
};

export default Footer;
