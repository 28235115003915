import { lazy } from 'react';
import { Routes } from 'src/constant/WebRoutes';

const Routers = [
  {
    path: '/',
    exact: true,
    redirect: Routes.login,
  },
  {
    path: Routes.login,
    exact: true,
    component: lazy(() => import('src/views/Login')),
  },
  {
    path: Routes.signup,
    exact: true,
    component: lazy(() => import('src/views/Signup')),
  },
  {
    path: Routes.signupCompany,
    exact: true,
    component: lazy(() => import('src/views/Signup/Company')),
  },
  {
    path: Routes.onboardingLanding,
    exact: true,
    component: lazy(() => import('src/views/Login/OnboardingLanding')),
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: Routes.onboardQuestion,
    exact: true,
    component: lazy(() => import('src/views/Login/OnboardingQuestion')),
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: Routes.home,
    exact: true,
    component: lazy(() => import('src/views/Home')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [1, 2, 3, 4],
  },
  {
    path: Routes.newpassword,
    exact: true,
    component: lazy(() => import('src/views/Newpassword')),
  },
  {
    path: Routes.forgotpassword,
    exact: true,
    component: lazy(() => import('src/views/ForgotPassword')),
  },
  {
    path: Routes.dashboard,
    exact: true,
    component: lazy(() => import('src/views/Dashboard')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [1, 2, 3, 4],
  },
  {
    path: Routes.teamdashboard,
    exact: true,
    component: lazy(() => import('src/views/TeamDashboard')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3],
  },
  {
    path: Routes.companydirectory,
    exact: true,
    component: lazy(() => import('src/views/CompanyDirectory')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: `${Routes.employeedirectory}/:userId`,
    exact: true,
    component: lazy(() => import('src/views/EmployeeDirectory')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3],
  },
  {
    path: `${Routes.groupMeetings}`,
    exact: true,
    component: lazy(() => import('src/views/GroupMeetings')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: `${Routes.groupMeetingTimeline}`,
    exact: true,
    component: lazy(() => import('src/views/GroupMeetings/timeline')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: `${Routes.myprofile}${Routes.employeedirectory}`,
    exact: true,
    component: lazy(() => import('src/views/EmployeeDirectory')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: Routes.kpilist,
    exact: true,
    component: lazy(() => import('src/views/KpiList')),
    routeType: 'private',
    layout: 'HeaderLayout',
    roleIds: [2, 3],
  },
  {
    path: Routes.metrics,
    exact: true,
    component: lazy(() => import('src/views/Metrics')),
    routeType: 'private',
    layout: 'HeaderLayout',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: Routes.myprofile,
    exact: true,
    component: lazy(() => import('src/views/Profile')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: Routes.cadashboard,
    exact: true,
    component: lazy(() => import('src/views/CADashboard')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  // {
  //   path: Routes.cajobtitle,
  //   exact: true,
  //   component: lazy(() => import("src/views/CompanyAdmin/jobTitle")),
  //   layout: "HeaderLayout",
  //   routeType: "private",
  //   roleIds: [2],
  //   screenTypes: ['client_admin']
  // },
  {
    path: `${Routes.casetting}${Routes.goals}`,
    exact: true,
    component: lazy(() => import('src/views/CompanyAdminSettings')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: `${Routes.casetting}${Routes.questionner}`,
    exact: true,
    component: lazy(() => import('src/views/CompanyAdminSettings')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: `${Routes.casetting}${Routes.setting}`,
    exact: true,
    component: lazy(() => import('src/views/CompanyAdminSettings')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: `${Routes.casetting}${Routes.jobtitle}`,
    exact: true,
    component: lazy(() => import('src/views/CompanyAdminSettings')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: `${Routes.casetting}${Routes.department}`,
    exact: true,
    component: lazy(() => import('src/views/CompanyAdminSettings')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: `${Routes.casetting}${Routes.logo}`,
    exact: true,
    component: lazy(() => import('src/views/CompanyAdminSettings')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: Routes.notification,
    exact: true,
    component: lazy(() => import('src/views/Notification')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [1, 2, 3, 4],
  },
  {
    path: Routes.todos,
    exact: true,
    component: lazy(() => import('src/views/TodoDashboard')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2, 3, 4],
  },
  {
    path: Routes.cacsvuserregistration,
    exact: true,
    component: lazy(() => import('src/views/CAMultiUserRegistration')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [2],
    screenTypes: ['client_admin'],
  },
  {
    path: Routes.padashboard,
    exact: true,
    component: lazy(() => import('src/views/PADashboard')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [1],
  },
  {
    path: Routes.paprofile,
    exact: true,
    component: lazy(() => import('src/views/PAProfile')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [1],
  },
  {
    path: Routes.pacompanydirectory,
    exact: true,
    component: lazy(() => import('src/views/PACompanyDirectory')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [1],
  },
  {
    path: Routes.paadmin,
    exact: true,
    component: lazy(() => import('src/views/PAAdmin')),
    layout: 'HeaderLayout',
    routeType: 'private',
    roleIds: [1],
  },
  {
    path: Routes.azureLandingPage,
    exact: true,
    component: lazy(() => import('src/views/AzureLandingPage')),
  },
  {
    path: Routes.azureLogoutPage,
    exact: true,
    component: lazy(() => import('src/views/AzureLogoutPage')),
    forceClose: true,
  },
  {
    path: Routes.versionHistory,
    exact: true,
    component: lazy(() => import('src/views/VersionHistory')),
    // layout: "HeaderLayout",
    routeType: 'private',
    roleIds: [1],
  },
  {
    path: Routes.versionHistoryDetails,
    exact: true,
    component: lazy(() =>
      import('src/views/VersionHistory/VersionHistoryDetails')
    ),
    // layout: "HeaderLayout",
    routeType: 'private',
    roleIds: [1, 2, 3, 4],
  },
  {
    path: Routes.versionHistoryDetailsFromLogin,
    exact: true,
    component: lazy(() =>
      import('src/views/VersionHistory/VersionHistoryDetails')
    ),
    // layout: "HeaderLayout",
    // routeType: "private",
    // roleIds: [1, 2, 3, 4],
  },
  // Wildcard Route
  {
    path: '**',
    exact: true,
    redirect: Routes.login,
  },
];

export default Routers;
