import { headerSearchField } from "src/actions/application";
import { getNotificationCountDetails } from "src/service/notification.service";
import { updateCountDetail } from "src/actions/application";
import { updateAlertMessage } from "src/actions";
import dompurify from 'dompurify';

export const getUrlDefiner = (params = {}) => {
  let urls = ``;
  if (Object.keys(params).length) {
    const entryparam = Object.entries(params);
    const querystr = entryparam.reduce((acc, val, index) => {
      let acc1 = acc;
      if (Array.isArray(val) && (typeof val[1] !== 'undefined' && val[1] !== null)) {
        if (index !== 0) {
          acc1 = acc1.concat('&');
        }
        acc1 = acc1.concat(val[0].toString(), '=', val[1].toString());
      }
      return acc1;
    }, '?');
    if (querystr) {
      urls = urls.concat('/', querystr);
    }
  }

  return urls;
}



export const userdatamodification = (arrs) => {
  function numberremodel(cnt) {
    if (!cnt.phoneNumber) {
      return '';
    } else {
      cnt = cnt.phoneNumber;
    }
    if (/\+/gim.test(cnt)) {
      cnt = cnt.replace(/\+/gim, '');
    }
    const regpattern = /^([0-9]{3})?([0-9]{3})?([0-9]{4})/gim;
    if (cnt && regpattern.test(cnt)) {
      const comparestr = cnt
        .toString()
        .replace(regpattern, '($1) $2-$3');

      if (comparestr) {
        return comparestr.substring(0, 14);
      }
    } else {
      // return '+1'.concat(cnt)
    }
    return cnt;
  }
  const avatar_letter = (e) => {
    if (!e.firstName) { return "" }
    if (e.firstName && e.lastName && (e.lastName !== "null")) {
      return e.firstName[0].concat("", e.lastName[0])
    } else if (e.firstName) {
      return e.firstName[0]
    }
    return ""
  }
  const manager_name = (e) => {
    if (!e.managerId) { return "" }
    if (e.managerFirstName && e.managerlastName && e.managerlastName !== "null") {
      return e.managerFirstName.concat(" ", e.managerlastName)
    } else if (e.managerFirstName) {
      return e.managerFirstName
    }
    return ""
  }
  const arrdata = (arrs || []).map((e) => {
    return {
      ...e,
      key: e.id,
      label: (e.lastName && e.lastName !== "null") ? e.firstName.concat(' ', e.lastName) : e.firstName,
      value: e.id,
      name: (e.lastName && e.lastName !== "null") ? e.firstName.concat(' ', e.lastName) : e.firstName,
      checkedstatus: false,
      avatar_letter: avatar_letter(e),
      managername: manager_name(e),
      phoneNumberModification: numberremodel(e)
    }
  });

  return arrdata
};


export const resetHeaderSearchDetail = (dispatch) => {
  dispatch(headerSearchField(''));
  setTimeout(() => {
    const elems = document.getElementById("headerSearch");
    if (elems) {
      elems.value = ''
    }
  }, 500);
}


export const setNotificationCount = async (dispatch) => {
  const data = await getNotificationCountDetails();
  if (data && data.data) {
    const { data: { error: ermsg, count } } = data;
    if (ermsg) {
      dispatch(
        updateAlertMessage({
          type: "error",
          message: ermsg.message
        })
      )
      dispatch(updateCountDetail(0));
      return;
    }
    if (typeof (count) === "number") {
      dispatch(updateCountDetail(count));
    }
  }
}






export const downloadTemplate = (azureAccess = false) => {
  const wb = window.XLSX.utils.book_new();
  // const newSheet = window.XLSX.utils.json_to_sheet
  wb.SheetNames.push("Sheet");
  var ws_data = [['Employee Code (optional)', 'First Name (required)', 'Last Name (required)', 'Email (required)', 'Job Title (optional)', 'Role [manager,employee] (required)', 'Date of Joining (MM/DD/YYYY)', 'Manager Employee Code (optional)', 'Manager Email (optional)']];
  if(azureAccess){
    ws_data[0].push('Azure Username');
  }
  // console.log({
  //   ws_data
  // })
  var ws = window.XLSX.utils.aoa_to_sheet(ws_data);
  ws['!cols'] = [
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
  ];
  if(azureAccess){
    ws['!cols'].push({ wch: 30 });
  }
  wb.Sheets["Sheet"] = ws;
  var wbout = window.XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  var buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i = 0; i < wbout.length; i++) { view[i] = wbout.charCodeAt(i) & 0xFF }; //convert to octet
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  var blob = new Blob([buf], { type: "application/octet-stream" });
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = 'Template.xlsx';
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove()
}

export const sanitizer = dompurify.sanitize;

