// // #region Global Imports
// import axios from "axios";
// // #endregion Global Imports
// const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );
// const getAppHeader = () => {
//   return { "Access-Control-Allow-Origin": "*" };
// };
// class HttpClient {
//   async getApi(url) {
//     const response = await axios.get(`${baseUrl}${url}`);
//     return response;
//   }
//   async postApi(url, body, header) {
//     try {
//       const response = await axios.post(`${baseUrl}${url}`, body, {
//         ...header,
//         ...getAppHeader(),
//       });
//       return response;
//     } catch (err) {
//       return err.response;
//     }
//   }
// }
// export default new HttpClient();
import axios from "axios";
import { ApiRoutes } from "src/constant/ApiRoutes";
import { Routes } from "src/constant/WebRoutes";
import { dataUrl } from "./config";
import TokenServices from "./TokenServices";

const instance = axios.create({
  baseURL: `${dataUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenServices.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // for Spring Boot back-end
      // config.headers["Authorization"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (!err.response) {
      return;
    }
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 403) {
        // UnAutherized
        window.location.href = Routes.login;
        TokenServices.removeLocalStorage();
      }
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const res = await instance.post(ApiRoutes.refreshToken, {
            refreshToken: TokenServices.getLocalRefreshToken(),
          });

          const { accessToken, refreshToken } = res.data;
          if (accessToken && refreshToken) {
            TokenServices.updateLocalAccessAndRefreshToken(
              accessToken,
              refreshToken
            );
          } else {
            window.location.href = Routes.login;
            TokenServices.removeLocalStorage();
          }
          return instance(originalConfig);
        } catch (_error) {
          window.location.href = Routes.login;
          TokenServices.removeLocalStorage();
          return;
        }
      }

      if (err.response.status === 400 || err.response.status === 409) {
        return err.response;
      }
    }
    return;
  }
);
export default instance;
