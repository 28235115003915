import { combineReducers } from "redux";

import ApplicationReducer from "./application";
import CompanyDirectoryReducer from "./companydirectory";

const rootReducer = combineReducers({
  auth: ApplicationReducer,
  companydirectory:CompanyDirectoryReducer
});

export default rootReducer;
