module.exports = {
  // dataUrl : 'http://itechdemo.in/secapistaff/api/',
  dataUrl: process.env.REACT_APP_API_BASE_URL,
  version: "1.0.0",
  appColor: "",
  // reactUrl : process.env.REACT_APP_BASE_URL,
  reactUrl: "",
  emptyUser:
    "https://allworldpm.com/wp-content/uploads/2016/10/230x230-avatar-dummy-profile-pic.jpg",
};
