import HttpClient from "src/utils/HttpClient";
import { ApiRoutes } from "src/constant/ApiRoutes";

export const getCompanyDetails = async (url) => {
  try {
    const result = await HttpClient.get(url);
    return result;
  } catch (error) {
    return error;
  }
};

export const createCompany = async (payload) => {
  try {
    const result = await HttpClient.post(ApiRoutes.organization, payload);
    return result;
  } catch (error) {
    return error;
  }
};

export const updateCompany = async (id,payload) => {
  try {
    const result = await HttpClient.put(`${ApiRoutes.organization}/${id}`, payload);
    return result;
  } catch (error) {
    return error;
  }
};

export const updateCompanyArchive = async (id,payload) => {
  try {
    const result = await HttpClient.put(`${ApiRoutes.organization}/archived/${id}`, payload);
    return result;
  } catch (error) {
    return error;
  }
};