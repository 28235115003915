import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { saveAs } from 'file-saver';

import Routers from './routes';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import JwtService from 'src/utils/JwtService';
import {
  screenLoading,
  updateAlertMessage,
  userInformation,
} from 'src/actions';
import { Routes } from './constant/WebRoutes';
import { getFileBlob } from './service/auth.service';

function App() {
  const alertData = useSelector(state => state.auth.alertData);
  const userDetails = useSelector(state => state.auth.userInformation);
  const file = useSelector(state => state.auth.file);
  const loading = useSelector(state => state.auth.screenLoading);
  const dispatch = useDispatch();

  const [alertOption] = useState({
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

  useEffect(() => {
    if (alertData) {
      if (alertData.type === 'error') {
        return toast.error(alertData.message, alertOption);
      } else if (alertData.type === 'success') {
        return toast.success(alertData.message, alertOption);
      }
    }
  }, [alertData, alertOption]);
  useEffect(() => {
    if (file && file.id) {
      localFileDownlod(file.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (!userDetails.hasOwnProperty('id')) {
    const decodeTokenValues = JwtService.getTokenValues();
    if (decodeTokenValues) {
      dispatch(userInformation(decodeTokenValues));
    }
  }
  // one tab closed logout to all other tabs
  window.addEventListener('storage', event => {
    if (!event.storageArea.length) {
      window.location.href = Routes.login;
    }
  });
  const localFileDownlod = async id => {
    if (id) {
      dispatch(screenLoading(true));
      const response = await getFileBlob(id);
      dispatch(screenLoading(false));
      if (!response) return;
      if (response.status === 400) {
        const error = response.data.error;
        dispatch(
          updateAlertMessage({
            type: 'error',
            message: Array.isArray(error.message)
              ? error.message.toString()
              : error.message,
          })
        );
        return;
      }
      saveAs(response.data, file.fileName ? file.fileName : '');
    }
  };

  return (
    <>
      <ToastContainer className="toast-container" />
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}>
        <Switch>
          {Routers.map((prop, _key) => {
            return prop.routeType === 'private' ? (
              <PrivateRoute
                exact={prop.exact}
                path={prop.path}
                component={prop.component}
                layout={prop.layout}
                roleIds={prop.roleIds}
                screenTypes={prop.screenTypes}
              />
            ) : (
              <PublicRoute
                exact={prop.exact}
                path={prop.path}
                component={prop.component}
                layout={prop.layout}
                redirect={prop.redirect}
                forceClose={prop.forceClose}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
