import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from 'src/constant/WebRoutes';
import { setNotificationCount } from 'src/utils/Helper';
import {
  updateAlertMessage,
} from "src/actions";
import { getUrlDefiner } from 'src/utils/Helper';
import { ApiRoutes } from "src/constant/ApiRoutes";
import { getCompanyDetails } from 'src/service/padasboard.service';

import './sidebar.scss';

const Sidebar = () => {
  const interval = useRef();
  const history = useHistory();
  const location = useLocation();
  const userDetails = useSelector(state => state.auth.userInformation);
  const dispatch = useDispatch();
  const [orgLogo, setOrgLogo] = useState(null);
  const [tokenValue, setTokenValue] = useState(null);

  useEffect(() => {
    setTokenValue(userDetails);
    if (!/notification/gim.test(history.location.pathname)) {
      setNotificationCount(dispatch);
      interval.current = setInterval(() => {
      }, 600000);
      return () => {
        clearInterval(interval.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrgInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.organizationLogo]);

  const getOrgInfo = async (reloadImage) => {
    const searchdata = {
      id: userDetails.organizationId,
    };
    const searchurl = ApiRoutes.organization.concat(getUrlDefiner(searchdata));
    const {
      data: { error: companyError, data: companyData }
    } = await getCompanyDetails(searchurl);
    if (companyError) {
      setOrgLogo({});
      dispatch(
        updateAlertMessage({
          type: 'error',
          message:
            companyError.message || 'Error while getting company details',
        })
      );
      return;
    }
    // set the org logo
    if (companyData && companyData[0] && companyData[0].logo) {
      setOrgLogo(companyData[0].logo);
    }
  }

  const navigation = routing => {
    if (routing === 'dashboard') {
      switch (tokenValue.roleId) {
        case 1:
          history.push(Routes.padashboard);
          break;
        case 2:
        case 3:
        case 4:
          history.push(Routes.dashboard);
          break;
        default:
          break;
      }
    }

    if (routing === 'teamDashboard') {
      switch (tokenValue.roleId) {
        case 1:
        case 2:
        case 3:
          history.push(Routes.teamdashboard);
          break;
        default:
          break;
      }
    }

    if (routing === 'companyDirectory') {
      switch (tokenValue.roleId) {
        case 1:
          history.push(Routes.paadmin);
          break;
        case 2:
          if (tokenValue.screen === 'client_admin') {
            history.push(Routes.cadashboard);
          }
          else {
            history.push(Routes.companydirectory);
          }
          break;
        case 3:
        case 4:
          history.push(Routes.companydirectory);
          break;
        default:
          break;
      }
    }
    if (routing === 'caSetting') {
      switch (tokenValue.roleId
      ) {
        case 1:
        case 2:
          history.push(`${Routes.casetting}${Routes.goals}`);
          break;
        case 3:
        case 4:
          history.push(Routes.companydirectory);
          break;
        default:
          break;
      }
    }
    if (routing === 'kpilist') {
      switch (tokenValue.roleId
      ) {
        case 1:
          break;
        case 2:
        case 3:
          history.push(Routes.kpilist);
          break;
        case 4:
          break;
        default:
          break;
      }
    }
    if (routing === 'metrics') {
      switch (tokenValue.roleId) {
        case 2:
        case 3:
          history.push(Routes.metrics);
          break;
        case 4:
          history.push(Routes.companydirectory);
          break;
        default:
          break;
      }
    }
    if (routing === 'groupMeetings') {
      switch (tokenValue.roleId) {
        case 2:
        case 3:
        case 4:
          history.push(Routes.groupMeetings);
          break;
        default:
          break;
      }
    }
    if (routing === 'todos') {
      switch (tokenValue.roleId) {
        case 2:
        case 3:
        case 4:
          history.push(Routes.todos);
          break;
        default:
          break;
      }
    }
  };
  const highLightMenus = () => {
    let highlightOption = '';
    switch (userDetails.roleId) {
      case 1:
        highlightOption = 1;
        break;
      case 2:
        if (userDetails.screen === "client_admin") {
          highlightOption = 2;
        }
        else {
          highlightOption = 3;
        }
        break;
      case 3:
      case 4:
        highlightOption = 3;
        break;
      default:
        highlightOption = '';
        break;
    }
    return highlightOption;
  };
  return (
    <div className='side-menu'>
      <div className='normal-menu'>
        <img
          className='icons pt-01'
          src='/assets/images/connectus_logo.svg'
          alt=''
          onClick={() => history.push('/')}
        ></img>
      </div>

      <div className='d-flex flex-column'>
        {/* Dashboard Sidebar Item */}
        {[2, 3, 4].includes(
          tokenValue && tokenValue.roleId ? tokenValue.roleId : ''
        ) && !['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu' onClick={() => navigation('dashboard')}>
            {[
              Routes.padashboard,
              Routes.dashboard,
            ].includes(location.pathname) ? (
              <div className={`highlighted-menu-item-${highLightMenus()}`}>
                <img
                  className='icons'
                  src={`/assets/images/${highLightMenus()}-home.svg`}
                  alt=''
                ></img>
                <div className='menu-label'><h5 style={{ color: 'white' }}>Dashboard</h5></div>
              </div>
            ) : (
              <div className='nonhighlighted-menu-item'>
                <img className='icons' src='/assets/images/home.svg' alt=''></img>
                <div className='menu-label'><h5>Dashboard</h5></div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {/* Team Dashboard Sidebar Item - only for roleId = 2, 3 (managers)*/}
        {[2, 3].includes(
          tokenValue && tokenValue.roleId ? tokenValue.roleId : ''
        ) && !['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu' onClick={() => navigation('teamDashboard')}>
            {[
              Routes.padashboard,
              Routes.teamdashboard,
            ].includes(location.pathname) ? (
              <div className={`highlighted-menu-item-${highLightMenus()}`}>
                <img
                  className='icons'
                  src={`/assets/images/${highLightMenus()}-team.svg`}
                  alt=''
                ></img>
                <div className='menu-label'><h5 style={{ color: 'white' }}>Team</h5></div>
              </div>
            ) : (
              <div className='nonhighlighted-menu-item'>
                <img className='icons' src='/assets/images/team.svg' alt=''></img>
                <div className='menu-label'><h5>Team</h5></div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {/* Settings sidebar Item - only for client admins)*/}
        {['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu' onClick={() => navigation('caSetting')}>
            {location.pathname.includes(Routes.casetting.concat('/')) ? (
              <div className={`highlighted-menu-item-${highLightMenus()}`}>
                <img
                  className='icons'
                  src={`/assets/images/${highLightMenus()}-settings.svg`}
                  alt=''
                ></img>
                <div className='menu-label'><h5 style={{ color: 'white' }}>Settings</h5></div>
              </div>
            ) : (
              <div className='nonhighlighted-menu-item'>
                <img
                  className='icons'
                  src='/assets/images/settings.svg'
                  alt=''
                ></img>
                <div className='menu-label'><h5>Settings</h5></div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {/* Company Directory sidebar Item - for everyone)*/}
        <div
          className='normal-menu'
          onClick={() => navigation('companyDirectory')}
        >
          {[
            Routes.paadmin,
            Routes.companydirectory,
            Routes.cadashboard,
          ].includes(location.pathname) ? (
            <div className={`highlighted-menu-item-${highLightMenus()}`}>
              <img
                className='icons'
                src={`/assets/images/${highLightMenus()}-directory.svg`}
                alt=''
              ></img>
              {['client_admin'].includes(
                tokenValue && tokenValue.screen ? tokenValue.screen : ''
              ) ?
                <div className='menu-label'><h5 style={{ color: 'white' }}>Users</h5></div>
                :
                <div className='menu-label'><h5 style={{ color: 'white' }}>Directory</h5></div>
              }
            </div>
          ) : (
            <div className='nonhighlighted-menu-item'>
              <img
                className='icons'
                src='/assets/images/directory.svg'
                alt=''
              ></img>
              {['client_admin'].includes(
                tokenValue && tokenValue.screen ? tokenValue.screen : ''
              ) ?
                <div className='menu-label'><h5>Users</h5></div>
                :
                <div className='menu-label'><h5>Directory</h5></div>
              }
            </div>
          )}
        </div>
        {[2, 3, 4].includes(
          tokenValue && tokenValue.roleId ? tokenValue.roleId : ''
        ) && !['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu'>
            {location.pathname.includes(Routes.groupMeetings) ? (
              <div className={`highlighted-menu-item-${highLightMenus()}`}>
                <img
                  className='icons'
                  src={`/assets/images/${highLightMenus()}-group-meetings.svg`}
                  alt=''
                ></img>
                <div className='menu-label'><h5 style={{ color: 'white' }}>Group Meetings</h5></div>
              </div>
            ) : (
              <div className='nonhighlighted-menu-item' onClick={() => navigation('groupMeetings')}>
                <img
                  className='icons'
                  src='/assets/images/group-meetings.svg'
                  alt=''
                ></img>
                <div className='menu-label'><h5>Group Meetings</h5></div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {[2, 3, 4].includes(
          tokenValue && tokenValue.roleId ? tokenValue.roleId : ''
        ) && !['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu'>
            {location.pathname.includes(Routes.todos) ? (
              <div className={`highlighted-menu-item-${highLightMenus()}`}>
                <img
                  className='icons'
                  src={`/assets/images/${highLightMenus()}-todo.svg`}
                  alt=''
                ></img>
                <div className='menu-label'><h5 style={{ color: 'white' }}>Todo List</h5></div>
              </div>
            ) : (
              <div className='nonhighlighted-menu-item' onClick={() => navigation('todos')}>
                <img
                  className='icons'
                  src='/assets/images/todo.svg'
                  alt=''
                ></img>
                <div className='menu-label'><h5>Todo List</h5></div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}

        {[2, 3].includes(
          tokenValue && tokenValue.roleId ? tokenValue.roleId : ''
        ) && !['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu'>
            {location.pathname.includes(Routes.kpilist) ? (
              <div className="highlighted-menu-item">
                <img
                  className='icons'
                  src={`/assets/images/${highLightMenus()}-kpi.svg`}
                  alt=''
                ></img>
                <div className='menu-label'><h5 style={{ color: 'white' }}>KPIs</h5></div>
              </div>
            ) : (
              <div className='nonhighlighted-menu-item' onClick={() => navigation('kpilist')}>
                <img
                  className='icons'
                  src='/assets/images/kpi.svg'
                  alt=''
                ></img>
                <div className='menu-label'><h5>KPIs</h5></div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}

        {['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu'>
            {location.pathname.includes(Routes.metrics) ? (
              <div className={`highlighted-menu-item-${highLightMenus()}`}>
                <img
                  className='icons'
                  src={`/assets/images/${highLightMenus()}-kpi.svg`}
                  alt=''
                ></img>
                <div className='menu-label'><h5 style={{ color: 'white' }}>Metrics</h5></div>
              </div>
            ) : (
              <div className='nonhighlighted-menu-item' onClick={() => navigation('metrics')}>
                <img
                  className='icons'
                  src='/assets/images/kpi.svg'
                  alt=''
                ></img>
                <div className='menu-label'><h5>Metrics</h5></div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}

        {[1, 2, 3, 4].includes(
          tokenValue && tokenValue.roleId ? tokenValue.roleId : ''
        ) && !['client_admin'].includes(
          tokenValue && tokenValue.screen ? tokenValue.screen : ''
        ) ? (
          <div className='normal-menu'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://docs.google.com/document/d/1ftLt-cIxQpWyym8r5B91FXp7iDSrBs_-Z5w7Bz_CqFo'
            >
              {location.pathname.includes(Routes.casetting.concat('/')) ? (
                <div>
                  <img
                    className='icons'
                    id='question-icon'
                    src={`/assets/images/${highLightMenus()}-question-mark.svg`}
                    alt=''
                  ></img>
                  <div className='menu-label'><h5 style={{ color: 'white' }}>Help</h5></div>
                </div>
              ) : (
                <div>
                  <img
                    className='icons'
                    id='question-icon'
                    src={`/assets/images/question-mark.svg`}
                    alt=''
                  ></img>
                  <div className='menu-label'><h5>Help</h5></div>
                </div>
              )}
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="flex-column d-flex-ja">
        {userDetails.organizationLogo ? (
          <div
            className="org-img"
            style={{
              backgroundImage: `url(${ApiRoutes.fileBaseUrl}${ApiRoutes.file}/${orgLogo})`,
              backgroundColor: "white",
            }}
          ></div>
        ) : (
          <div className="org-name">{userDetails.organizationName}</div>
        )}
      </div>
    </div >
  );
};

export default Sidebar;
