import { ApiRoutes } from "src/constant/ApiRoutes";
import HttpClient from "src/utils/HttpClient";

export const getVersionHistory = async (queryParams) => {
  try {
    const result = await HttpClient.get(
      `${ApiRoutes.versionHistory}${queryParams}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const addVersionHistory = async (bodyData) => {
  try {
    const result = await HttpClient.post(
      `${ApiRoutes.versionHistory}`,
      bodyData
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const updateVersionHistory = async (id, bodyData) => {
  try {
    const result = await HttpClient.put(
      `${ApiRoutes.versionHistory}/${id}`,
      bodyData
    );
    return result;
  } catch (error) {
    return error;
  }
};
