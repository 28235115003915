import { ActionConsts } from "../../definitions";
import { getUrlDefiner } from "src/utils/Helper";
import { getUserDetail } from "src/service/cacompanydirectory.service";
import { userdatamodification } from "src/utils/Helper";
import { updateAlertMessage } from "../application"


export const resetFieldvalues = () => {
  return {
    type: ActionConsts.CompanyDirectory.ON_RESET_FIELDS
  }
}


export const setCompanyDirectoryKeyValue = (key, value) => {
  return {
    type: ActionConsts.CompanyDirectory.ON_SET_FIELD_VALUE,
    key,
    value
  }
}

export const setCompanyDirectoryUserlist = (setLoader) => async (dispatch, getState) => {
  setLoader(true);
  const { companydirectory: { departmentId, jobTitleId, managerId }, auth: { headersearch } } = getState();
  const apidata = {
    departmentId, jobTitleId, managerId, name: headersearch,
    sortby: 'userName', order: 'ASC', archived: false
  }
  const searchurl = getUrlDefiner(apidata);
  const url = "users".concat(searchurl)
  const { data: { data, error } } = await getUserDetail(url);
  setLoader(false);
  let lists = [];
  if (error) {
    dispatch(
      updateAlertMessage({
        type: "error",
        message: error.message
      })
    )
  } else {
    lists = userdatamodification(data);
  }

  // const filterimg = lists.filter(e => e.imageId).map(e => ({...e,imageId:''}));
  // lists=filterimg

  dispatch({
    type: ActionConsts.CompanyDirectory.ON_SET_USER_LIST,
    payload: lists
  })
}