import { logoutApi } from "src/service/auth.service";
import JwtService from "./JwtService";

class TokenServices {
  getLocalRefreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    return refreshToken;
  }

  getLocalAccessToken() {
    const accessToken = localStorage.getItem("token");
    return accessToken;
  }

  updateLocalAccessAndRefreshToken(accessToken, refreshToken) {
    localStorage.setItem("token", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }

  removeLocalStorage(withOutApiCall) {
    const tokenValue = JwtService.getTokenValues();
    if (tokenValue && tokenValue.refreshTokenId && !withOutApiCall) {
      logoutApi(tokenValue.refreshTokenId);
    }
    localStorage.clear();
  }
}

export default new TokenServices();
