// #region Local Imports
import HttpClient from "src/utils/HttpClient";
import { ApiRoutes } from "src/constant/ApiRoutes";
// #endregion Local Imports

export const emailVerify = async (email) => {
  try {
    const emailEncode =encodeURIComponent(email);
    const result = await HttpClient.get(`${ApiRoutes.emailVerify}?emailHint=${emailEncode}`);
    return result;
  } catch (error) {
    return error;
  }
}

export const companyVerify = async (email) => {
  try {
    const emailEncode =encodeURIComponent(email);
    const result = await HttpClient.get(`${ApiRoutes.companyVerify}?emailHint=${emailEncode}`);
    return result;
  } catch (error) {
    return error;
  }
}

export const login = async (payload) => {
  try {
    const result = await HttpClient.post(ApiRoutes.login, payload);
    return result;
  } catch (error) {
    return error;
  }
};

export const createPassword = async (payload) => {
  try {
    const result = await HttpClient.post(ApiRoutes.password, payload);
    return result;
  } catch (error) {
    return error;
  }
};


export const logoutApi = async (refreshTokenId) => {
  try {
    const result = await HttpClient.delete(`${ApiRoutes.logout}/${refreshTokenId}`, {});
    return result;
  } catch (error) {
    return error;
  }
}

export const getFileBlob = async (fileId) => {
  try {
    const result = await HttpClient.get(`${ApiRoutes.file}/${fileId}`, { responseType: 'blob' });
    return result;
  } catch (error) {
    return error;
  }
};

export const exchangeToken = async (bodyData) => {
  try {
    const result = await HttpClient.post(`${ApiRoutes.refreshToken}`, bodyData);
    return result;
  } catch (error) {
    return error;
  }
};