import HttpClient from "src/utils/HttpClient";
import { ApiRoutes } from "src/constant/ApiRoutes";

export const getUserDetail = async (url) => {
  try {
    const result = await HttpClient.get(url);
    return result;
  } catch (error) {
    return error;
  }
};


export const getManagerDetail = async () => {
  try {
    const result = await HttpClient.get(`${ApiRoutes.users}?roleId=3&sortby=firstName&order=ASC&skip=0&take=`);
    return result;
  } catch (error) {
    return error;
  }
};

export const getRoles = async () => {
  try {
    const result = await HttpClient.get(ApiRoutes.roles);
    return result;
  } catch (error) {
    return error;
  }
};
