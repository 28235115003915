// #region Local Imports
import HttpClient from "src/utils/HttpClient";
import { ApiRoutes } from "src/constant/ApiRoutes";
// #endregion Local Imports

export const getNotificationDetails = async (queryParams) => {
    try {
        const result = await HttpClient.get(`${ApiRoutes.notifications}${queryParams}`);
        return result;
    } catch (error) {
        return error;
    }
};

export const getNotificationCountDetails = async () => {
    try {
        const result = await HttpClient.get(`${ApiRoutes.notifications}/count`);
        return result;
    } catch (error) {
        return error;
    }
};


export const updateNotificationsApi = async (data) => {
    try {
        const result = await HttpClient.put(`${ApiRoutes.notifications}/flag`,data);
        return result;
    } catch (error) {
        return error;
    }
};
