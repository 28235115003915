/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
// #region Local Imports
import { ActionConsts } from "src/definitions";
// #endregion Local Imports

const initialState = {
  loading: false,
  alertData: {},
  token: null,
  headersearch: "",
  userInformation: {},
  multiUserRegistrationDetails: [],
  file: {},
  screenLoading: false,
  notificationCount:0
};

const ApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConsts.Auth.ON_LOADING_START: {
      return {
        ...initialState,
        loading: true,
      };
    }

    case ActionConsts.Auth.ON_LOADING_FINISHED: {
      return {
        ...state,
        loading: false,
      };
    }

    case ActionConsts.Auth.UPDATE_ALERT_MESSAGE: {
      return {
        ...state,
        alertData: action.data,
      };
    }

    case ActionConsts.Auth.USER_INFORMATION: {
      return {
        ...state,
        userInformation: action.data,
      };
    }

    case ActionConsts.Auth.ORG_INFORMATION: {
      return {
        ...state,
        orgInformation: action.data,
      }
    }

    case ActionConsts.Auth.UPDATE_USER_TOKEN: {
      return {
        ...state,
        token: action.data,
      };
    }

    case ActionConsts.Header.ON_SEARCH_FIELD: {
      return {
        ...state,
        headersearch: action.payload,
      };
    }

    case ActionConsts.Auth.UPDATE_MULTIUSER_DETAIL: {
      return {
        ...state,
        multiUserRegistrationDetails: action.payload
      }
    }

    case ActionConsts.File.FILE_DOWNLOAD: {
      return {
        ...state,
        file: action.payload,
      };
    }

    case ActionConsts.Auth.SCREEN_LOADING: {
      return {
        ...state,
        screenLoading: action.payload,
      };
    }

    case ActionConsts.Auth.SET_NOTIFICATION_DETAIL:{
      return {
        ...state,
        notificationCount:action.payload
      }
    }

    default: {
      return state;
    }
  }
};

export default ApplicationReducer;
