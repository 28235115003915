import { ActionConsts } from "src/definitions";
const initialState = {
    jobTitleId: '',
    managerId: '',
    userList: []
};

const CompanyDirectoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConsts.CompanyDirectory.ON_SET_FIELD_VALUE: {
            return {
                ...state,
                [action.key]: action.value
            }
        }
        case ActionConsts.CompanyDirectory.ON_SET_USER_LIST: {
            return {
                ...state,
                userList: action.payload
            }
        }
        case ActionConsts.CompanyDirectory.ON_RESET_FIELDS:{
            return{
                ...state,
                ...initialState
            }
        }
        default: {
            return state;
        }
    }
};

export default CompanyDirectoryReducer;
