export const Routes = {
  login: '/login',
  signup: '/signup',
  signupCompany: '/signup-company',
  onboardingLanding: '/onboard-landing-page',
  onboardQuestion: '/onboard-qus',
  newpassword: '/password',
  forgotpassword: '/forgot-password',
  home: '/home',
  dashboard: '/dashboard',
  teamdashboard: '/team-dashboard',
  companydirectory: '/company-directory',
  employeedirectory: '/employee-directory',
  groupMeetings: '/group-meetings',
  groupMeetingTimeline: '/group-meeting-timeline',
  kpilist: '/kpi-list',
  metrics: '/metrics',
  myprofile: '/profile',
  cadashboard: '/company-dashboard',
  cajobtitle: '/company-dashboard/job-title',
  casetting: '/company-setting',
  cacsvuserregistration: '/company-multiuser-registration',
  notification: '/notification',
  todos: '/todos',
  goals: '/goals',
  questionner: '/questionner',
  setting: '/settings',
  jobtitle: '/jobtitle',
  department: "/department",
  logo: "/logo",
  padashboard: '/product-dashboard',
  pacompanydirectory: '/product-company-directory',
  paprofile: '/product-profile',
  paadmin: '/product-admins',
  azureLandingPage: '/azure/redirect-page',
  azureLogoutPage: '/azure/logout',
  versionHistory: '/version-history',
  versionHistoryDetails: '/version-history-details',
  versionHistoryDetailsFromLogin: '/version-history-detail',
};
