import React, { useState, useRef, useEffect } from "react";
import { Radio } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as _ from "lodash";

import "./header.scss";
import { headerSearchField } from "src/actions/application";
import { updatUserToken, userInformation, screenLoading } from "src/actions";
import { exchangeToken, logoutApi } from "src/service/auth.service";
import TokenServices from "src/utils/TokenServices";
import { userdatamodification } from "src/utils/Helper";
import { ApiRoutes } from "src/constant/ApiRoutes";
import JwtService from "src/utils/JwtService";
import { Routes } from "src/constant/WebRoutes";
import { getVersionHistory } from "src/service/version.service";
import { updateAlertMessage } from "src/actions";
import { getUrlDefiner } from "src/utils/Helper";

const Header = () => {
  const headerSearch = useRef(
    _.debounce((value) => dispatchHeaderSearch(value), 1000)
  );
  const [searchstr, setSearchstr] = useState("");
  const [chooseRole, setChooseRole] = useState("");
  const [version, setVersion] = useState({ data: {}, total: 0 });
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  // Reading UserInfo From redux
  // const searchval = useSelector((state) => state.auth.headersearch);
  const userDetails = useSelector((state) => state.auth.userInformation);

  const [hovercls, sethovercls] = useState(false);
  useEffect(() => {
    setSearchstr("");
    dispatchHeaderSearch("");
    setChooseRole(userDetails && userDetails.screen ? userDetails.screen : "");
    getVersionHistoryData({
      skip: 0,
      take: 1,
      sortby: "createdAt",
      order: "DESC",
    });
    // setSearchstr(searchval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tokenValue = userDetails;
  const resetdata = userdatamodification([tokenValue]);
  if (resetdata && resetdata.length) {
    tokenValue = resetdata[0];
  }

  const dispatchHeaderSearch = (value) => {
    dispatch(headerSearchField(value));
  };

  const searchcontent = (event) => {
    setSearchstr(event.target.value);
    headerSearch.current(event.target.value);
  };

  const getVersionHistoryData = async (queryParam) => {
    const searchparams = {
      skip: queryParam.skip,
      take: queryParam.take,
      sortby: queryParam.sortby,
      order: queryParam.order,
    };
    const searchurl = getUrlDefiner(searchparams);
    const datas = await getVersionHistory(searchurl);
    const { data, total, error, status } = datas
      ? datas
      : {
        data: [],
        total: 0,
        error: { message: "Error while getting version history details" },
        status: 400,
      };
    if (error) {
      dispatch(
        updateAlertMessage({
          type: "error",
          message: error.message
            ? error.message
            : "Error while getting version history details",
        })
      );
    }
    if (status === 200) {
      setVersion({
        data: data && data.data ? data.data[0] : { versionId: "" },
        total,
      });
    }
  };
  const versionRedirect = () => {
    history.push(Routes.versionHistoryDetails);
  };

  const profileRedirect = () => {
    switch (tokenValue && tokenValue.roleId) {
      case 1:
        history.push(Routes.paprofile);
        break;
      case 2:
      case 3:
      case 4:
        history.push(`${Routes.myprofile}?profileId=${tokenValue.id}`)
        history.go();
        break;
      default:
        break;
    }
  };
  const logout = async () => {
    if (tokenValue && tokenValue.clientPreference === "azureCredential") {
      dispatch(screenLoading(true));
      if (tokenValue && tokenValue.refreshTokenId) {
        await logoutApi(tokenValue.refreshTokenId);
      }
      dispatch(screenLoading(false));
      TokenServices.removeLocalStorage("with out api call");
      window.open(
        `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.origin}/azure/logout`,
        "_self"
      );
      return;
    }
    dispatch(screenLoading(true));
    if (tokenValue && tokenValue.refreshTokenId) {
      await logoutApi(tokenValue.refreshTokenId);
    }
    dispatch(screenLoading(false));
    TokenServices.removeLocalStorage("with out api call");
    history.push(Routes.login);
  };
  const headerDropdownview = () => {
    sethovercls(hovercls ? false : true);
  };
  const tabChanges = async (val) => {
    const bodyData = {
      refreshToken: TokenServices.getLocalRefreshToken(),
      screen: val,
      type: "screenSwitching",
    };
    dispatch(screenLoading(true));
    const response = await exchangeToken(bodyData);
    dispatch(screenLoading(false));
    if (response && response.data) {
      const { accessToken, refreshToken, firstTimeLogin } = response.data;
      dispatch(updatUserToken({ token: accessToken }));
      if (accessToken && refreshToken) {
        TokenServices.updateLocalAccessAndRefreshToken(
          accessToken,
          refreshToken
        );
        const decodeTokenValues = JwtService.getTokenValues();
        if (decodeTokenValues) {
          dispatch(userInformation(decodeTokenValues));
        }
        switch (val) {
          case "client_admin":
            history.push(Routes.casetting);
            break;
          case "manager":
            firstTimeLogin
              ? history.push(Routes.onboardingLanding)
              : history.push(`${Routes.dashboard}`);
            break;
          default:
            break;
        }
      }
      setChooseRole(val);
    }
  };

  return (
    <div className="header-box">
      <div className="search-box">
        {[Routes.myprofile, Routes.teamdashboard].includes(location.pathname) ||
          location.pathname.includes(Routes.employeedirectory) ? (
          ""
        ) : (
          <>
            <img src="/assets/icons/search.svg" alt="search" />
            <input
              autoComplete="off"
              type="search"
              id="headerSearch"
              value={searchstr}
              onInput={(e) => searchcontent(e)}
              placeholder={
                [Routes.teamdashboard].includes(location.pathname)
                  ? "Search my team"
                  : "Search"
              }
            />
          </>
        )}
      </div>
      <div className="d-flex-a">
        <div className="version" onClick={() => versionRedirect()}>
          {`v${version?.data?.versionId ? version?.data?.versionId : "0.0.0"}`}
        </div>
        {tokenValue && tokenValue.roleId && [2].includes(tokenValue.roleId) ? (
          <Radio.Group
            value={chooseRole}
            className="role-btn"
            onChange={(e) => tabChanges(e.target.value)}
          >
            {tokenValue &&
              tokenValue.roleId &&
              [2].includes(tokenValue.roleId) ? (
              <Radio.Button
                value="manager"
                className={chooseRole === "manager" ? "active-btn" : ""}
              >
                Manager
              </Radio.Button>
            ) : (
              ""
            )}
            {tokenValue &&
              tokenValue.roleId &&
              [2].includes(tokenValue.roleId) ? (
              <Radio.Button
                value="client_admin"
                className={chooseRole === "client_admin" ? "active-btn" : ""}
              >
                Admin
              </Radio.Button>
            ) : (
              ""
            )}
          </Radio.Group>
        ) : (
          ""
        )}
        <div
          className="profile-menu-option d-flex-a"
          onClick={headerDropdownview}
        >
          {tokenValue.imageId ? (
            <div
              className="profile-img"
              style={{
                backgroundImage: `url(${ApiRoutes.fileBaseUrl}${ApiRoutes.file}/${tokenValue.imageId})`,
                backgroundColor: "unset",
              }}
            ></div>
          ) : (
            <div className="profile-img d-flex-ja">
              {tokenValue.avatar_letter}
            </div>
          )}
          <img
            src="/assets/images/arrow-down.svg"
            className="arrow-block"
            alt="menu"
          />
          <img
            src="/assets/images/arrowDownGrey.svg"
            className="arrow-none"
            style={{ width: "14px" }}
            alt="menu"
          />
          <div
            class="tooltip-hover"
            className={`tooltip-hover ${hovercls && "tooltip-active"}`}
          >
            <div onClick={() => profileRedirect()}>View My Profile</div>
            <div onClick={() => logout()}>Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
