import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import JwtService from './utils/JwtService';
import * as Layoutsassign from 'src/layouts';
import { Routes } from './constant/WebRoutes';

const PrivateRoute = prop => {
  const tokenExpired = JwtService.isTokenExpired();
  const token = JwtService.getTokenValues();
  return tokenExpired ||
    !prop.roleIds.includes(token && token.roleId ? token.roleId : '') ||
    (prop.screenTypes &&
      !prop.screenTypes.includes(token && token.screen ? token.screen : '')) ? (
    <Redirect to={Routes.login} />
  ) : (
    <Route
      exact={prop.exact}
      path={prop.path}
      render={props => {
        const LayoutAssign = Layoutsassign[prop.layout];
        return (
          <>
            {prop.hasOwnProperty('layout') && prop.layout ? (
              <LayoutAssign>
                <prop.component {...props} />
              </LayoutAssign>
            ) : (
              <>
                {prop.hasOwnProperty('component') ? (
                  <prop.component {...props} />
                ) : (
                  <>
                    {prop.hasOwnProperty('redirect') && (
                      <Redirect to={prop.redirect} />
                    )}
                  </>
                )}
              </>
            )}
          </>
        );
      }}
      key={prop.path}
    />
  );
};
export default PrivateRoute;
