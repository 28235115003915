import { ActionConsts } from "../../definitions";

export const startLoading = () => {
  return {
    type: ActionConsts.Auth.ON_LOADING_START,
  };
};

export const stopLoading = () => {
  return {
    type: ActionConsts.Auth.ON_LOADING_FINISHED,
  };
};

export const updateAlertMessage = (data) => {
  return {
    type: ActionConsts.Auth.UPDATE_ALERT_MESSAGE,
    data,
  };
};

export const updatUserToken = (data) => {
  return {
    type: ActionConsts.Auth.UPDATE_USER_TOKEN,
    data,
  };
};

export const userInformation = (data) => {
  return {
    type: ActionConsts.Auth.USER_INFORMATION,
    data,
  };
};

export const orgInformation = (data) => {
  return {
    type: ActionConsts.Auth.ORG_INFORMATION,
    data,
  };
};

export const headerSearchField = (data) => {
  return {
    type: ActionConsts.Header.ON_SEARCH_FIELD,
    payload: data,
  };
};

export const multiUserDetailregistration = (data) => {
  return {
    type: ActionConsts.Auth.UPDATE_MULTIUSER_DETAIL,
    payload: data,
  };
}

export const downloadFile = (data) => {
  return {
    type: ActionConsts.File.FILE_DOWNLOAD,
    payload: data
  }
}

export const screenLoading = (data) => {
  return {
    type: ActionConsts.Auth.SCREEN_LOADING,
    payload: data
  }
}

export const updateCountDetail = (count) => {
  return {
    type: ActionConsts.Auth.SET_NOTIFICATION_DETAIL,
    payload: count
  }
}